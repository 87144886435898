.navbar {
  background-color: transparent;
  height: 0;
  min-height: 0;
}

#attribute-navbar {
  height: 3rem;
  background-color: #ffffff7a;
}

a {
  color: black;
}

.nav-logo {
  margin: 0 1.5rem 0 1.5rem;
}

.navbar-item {
  margin: 0 1rem 0 1rem;
}

.button {
  margin: 0 1.5rem 0 1.5rem;
}

.attLink:hover {
  cursor: pointer;
}

.user-dropdown {
  right: 0;
  left: auto;
}

.nested.dropdown {
  &:hover > .dropdown-menu {
    display: block;
  }
  .dropdown-menu {
    top: -15px;
    margin-left: 100%;
  }
  .dropdown-trigger {
    button::after {
      content: "⦠";
    }
    button {
      padding: 0px 0px;
      border: 0px;
      font-size: 14px;
      font-weight: 400;
      height: 2em;
      background-color: white;
    }
  }
}

.burger {
  right: 0;
  top: 0;
  position: fixed;
  margin: 0 1rem;
  padding: 0.5rem 0.75rem;
  color: white;
}

// sidebar

@import url("https://fonts.googleapis.com/css?family=Varela+Round");
html,
body {
  overflow-x: hidden;
  height: 100%;
}
body {
  background: transparent;
  padding: 0;
  margin: 0;
}
.header {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  background-color: #fc466b;
  position: fixed;
  height: 60px !important;
  overflow: hidden;
  z-index: 10;
}
.main {
  margin: 0 auto;
  display: block;
  height: 100%;
  margin-top: 60px;
}
.mainInner {
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
}
.mainInner div {
  display: table-cell;
  vertical-align: middle;
  font-size: 3em;
  font-weight: bold;
  letter-spacing: 1.25px;
}
#sidebarMenu {
  height: 100%;
  position: fixed;
  left: 0;
  width: 250px;
  transform: translateX(-250px);
  transition: transform 250ms ease-in-out;
  background: linear-gradient(180deg, var(--teal) 0%, var(--blue) 100%);
  z-index: 5;
}
.sidebarMenuInner {
  margin-top: 3rem;
  padding: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebarMenuInner li {
  list-style: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.507);
  width: 100%;
  height: 100%;
}
.sidebarMenuInner li:hover {
  background: brightness(85%);
}
.mobile-dropdown:hover {
  background: brightness(85%);
}
.sidebarMenuInner li span {
  display: block;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
.sidebarMenuInner li a {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}
input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateX(0);
}

input[type="checkbox"] {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}
.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  height: 100%;
  width: 100%;
  top: 22px;
  left: 15px;
  height: 22px;
  width: 22px;
}
.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: var(--orange);
  z-index: 100;
}
.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
}
.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
input[type="checkbox"]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}
input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 8px;
}
input[type="checkbox"]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  margin-top: -9px;
}
.mobile-dropdown {
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.507);
  width: 100%;
}
.mobile-dropdown-item {
  padding: 0.25rem;
}
.angle-down {
  margin-left: 1rem;
  font-size: 1.5rem;
  color: var(--orange);
}
.navbar-item.is-hoverable:hover .navbar-dropdown {
  display: block !important;
}
.navbar-item.is-hoverable:focus-within .navbar-dropdown {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .navbar {
    display: none;
  }
}
