/* ==========================================================================
Classes to change the feel of bulma buttons
========================================================================== */

// CTA buttons

.button {
    cursor: pointer;
    transition: all 0.5s;
    
    &:focus, &:active {
        outline: none;
    }

    &.cta {
        font-size: 1rem;
        font-weight: 500;
        height: 44px;
    }

    &.is-clear {
        line-height: 0 !important;
    }

    &.is-bold {
        font-weight: 500;
    }

    &.rounded {
        border-radius: 500px;
    }

    &.raised:hover {
        box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
        opacity: 0.8;
    }

    &.btn-outlined {
        background: transparent;
    }

    &.signup-button {
        font-weight: 500;
        height: 44px;
        width: 120px;
    }
}

.button {
    &.primary-btn {
        outline: none;
        border-color: $primary;
        background-color: $primary;
        color: $white;
        transition: all 0.5s;

        &:hover {
            color: $white;
        }

        &.raised:hover {
            box-shadow: 0 14px 26px -12px rgba($primary, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($primary, 0.2) !important;
            opacity: 0.8;
        }

        &.btn-outlined {
            border-color: $primary;
            color: $primary;
            background-color: transparent;

            &:hover {
                color: $white;
                background-color: $primary;
            }
        }
    }

    &.secondary-btn {
        outline: none;
        border-color: $secondary;
        background-color: $secondary;
        color: $white;
        transition: all 0.5s;

        &:hover {
            color: $white;
        }

        &.raised:hover {
            box-shadow: 0 14px 26px -12px rgba($secondary, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($secondary, 0.2) !important;
            opacity: 0.8;
        }

        &.btn-outlined {
            border-color: $secondary;
            color: $secondary;
            background-color: transparent;

            &:hover {
                color: $white;
                background-color: $secondary;
            }
        }
    }

    &.button.accent-btn {
        outline: none;
        border-color: $accent;
        background-color: $accent;
        color: $white;
        transition: all 0.5s;

        &:hover {
            color: $white;
        }

        &.raised:hover {
            box-shadow: 0 14px 26px -12px rgba($accent, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($accent, 0.2) !important;
            opacity: 0.8;
        }

        &.btn-outlined {
            border-color: $accent;
            color: $accent;
            background-color: transparent;

            &:hover {
                color: $white;
                background-color: $accent;
            }
        }
    }
}
