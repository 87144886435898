.sidebar-div {
  margin-bottom: -0.7rem;
}

.active {
  font-weight: 600;
}

.sidebar-ul .navbar-item {
  font-weight: 300;
  border-left: 2px solid #5f5d5d;
}

.current {
  display: block;
}

.hidden {
  display: none;
}
