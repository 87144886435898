.personality-quiz-section {
  background-color: var(--yellow);
  padding-top: 4rem;
  min-height: 100vh;
  height: fit-content;
}

.quiz-card {
  height: 100%;
}

.progress-bar-container {
  width: 100%;
  height: 2rem;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-button {
  cursor: pointer;
  width: 24px;
}

.quiz-container {
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  padding: 1rem 4rem;
  background-color: var(--yellow);
}

.quiz-container h2,
.quiz-container .is-4 {
  text-align: center;
}

.connector-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.connector-card {
  border-radius: 10px;
  max-width: 15rem;
  min-height: 8rem;
  flex-grow: 1;
}

.go-to-attribute-card {
  height: 16rem;
  width: 16rem;
}

.card-content {
  align-items: center;
}

.card-content p {
  text-align: center;
}

.title.is-4 {
  font-size: 1.4rem !important;
}

.media-image {
  max-width: 6rem;
}

.connector-divider {
  height: 3px;
  width: 60px;
  background-color: #4a4a4a;
  color: #4a4a4a;
  border-radius: 50px;
  margin-bottom: 2rem;
}

.political-positions {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1rem;
}

.political-positions div {
  flex: 1 1 200px;
  text-align: center;
  font-weight: 600;
  padding: 0.5rem;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.liberal {
  border: 1px solid var(--teal);
}

.conservative {
  border: 1px solid var(--orange);
}

@media screen and (max-width: 1024px) {
  .progress-bar-container {
    justify-content: center;
    gap: 1rem;
  }

  .progress-bar-back {
    padding-top: 8px;
  }
}

  @media screen and (max-width: 600px) {
    .personality-quiz-section {
    width: 100%;
  }

  .quiz-card {
    flex-direction: column-reverse;
    width: 100%;
    height: 100%;
  }

  .quiz-container {
    width: 100%;
    height: 30%;
    padding: 0.5rem 2rem;
  }

  .quiz-right {
    width: 100%;
    height: 70%;
  }

  .movie-synopsis {
    font-size: 1.2rem !important;
  }

  .title.is-3 {
    font-size: 1.5rem;
  }
}