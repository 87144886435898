.attribute-dropdown {
  display: flex;
  flex-wrap: wrap;
  width: 36rem;
}

.attribute-dropdown-item {
  width: 23%;
  box-sizing: border-box;
  margin: 0.3rem;
  padding: 0.8rem;
  text-align: center;
}

.attribute-dropdown-item:nth-child(-n + 8) {
  background-color: var(--teal);
}

.attribute-dropdown-item:nth-child(n + 9):nth-child(-n + 16) {
  background-color: var(--yellow);
}

.attribute-dropdown-item:nth-child(n + 17):nth-child(-n + 24) {
  background-color: var(--blue);
}

.attribute-dropdown-item:nth-child(n + 25):nth-child(-n + 32) {
  background-color: var(--orange);
}

/* mobile */
.mobile-array {
  display: flex;
  flex-flow: row wrap;
}
.mobile-att-name {
  padding: 0.4rem;
  font-size: 0.8rem;
}
.mobile-att-name:hover {
  background-color: lightgray;
}
