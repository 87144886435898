.quizPage {
  background-color: white;
  padding: 1rem;
}

.quiz-taken-message {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 10%;
  padding: 1rem;
  text-align: center;
}

.quiz-taken-buttons {
  text-align: center;
}

#header {
  font-size: 4rem;
  padding: 0rem 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 3rem;
  background-color: var(--yellow);
}

.quiz-footer {
  display: flex;
  justify-content: center;
}

#quiz-flex {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.question-inputs {
  display: flex;
  align-items: center;
  align-content: space-between;
}

input {
  margin: 1.2rem;
}

input[value="1"] {
  transform: scale(1.8);
}

input[value="2"] {
  transform: scale(1.4);
}

input[value="4"] {
  transform: scale(1.4);
}

input[value="5"] {
  transform: scale(1.8);
}

label {
  font-size: 1.1rem;
  margin-right: 0.5rem;
}

.question-card {
  padding: 2rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.question-header {
  font-size: 1.4rem;
}

.progess-bar {
  margin-top: 1rem;
}

.progress {
  margin: 0.3rem 0.4rem 0 0.4rem;
}

.justify-end {
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .question-header {
    font-size: 1rem;
  }
  .desktop-label {
    font-size: 0.8rem;
  }
  .question-card {
    padding: 1.5rem 0;
  }
  input {
    margin: 0.8rem;
  }
  .quiz-btn,
  .quizz-btn {
    font-size: 1.1rem !important;
  }
}

@media only screen and (max-width: 420px) {
  .desktop-label {
    font-size: 0.5rem;
  }
}
