html {
  overflow-y: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --teal: #00d1b2;
  --yellow: #fad355;
  --blue: #1cbfd4;
  --orange: #ec7b47;
}

.likeLink:hover {
  cursor: pointer;
}

.likeLink {
  color: var(--teal);
}

.button {
  background-color: var(--teal);
}

Link {
  background-color: var(--teal);
}

/* CARDS */
.card-content {
  display: flex;
  flex-flow: column nowrap;
  align-content: center;
  text-align: center;
}

.card-image {
  width: 100%;
  height: auto;
  padding: 1rem;
}

.card {
  margin: 1.5rem;
  width: 14rem;
}

/* block quotes */

.testimonial-quote {
  font-size: 16px;
}

.testimonial-quote blockquote {
  /* Negate theme styles */
  border: 0;
  margin: 0;
  padding: 0;

  background: none;
  color: gray;
  font-family: Georgia, serif;
  font-size: 1.5em;
  font-style: italic;
  line-height: 1.4 !important;
  margin: 0;
  position: relative;
  text-shadow: 0 1px white;
  z-index: 600;
}

.testimonial-quote blockquote * {
  box-sizing: border-box;
}

.testimonial-quote blockquote p {
  color: #75808a;
  line-height: 1.4 !important;
}

.testimonial-quote blockquote p:first-child:before {
  content: "\201C";
  color: #81bedb;
  font-size: 2rem;
  font-weight: 700;
  opacity: 0.3;
  position: absolute;
  top: -0.4em;
  left: -0.2em;
  text-shadow: none;
  z-index: -300;
}

.testimonial-quote cite {
  color: gray;
  display: block;
  font-size: 0.8em;
}

.testimonial-quote cite span {
  color: #5e5e5e;
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 1px white;
}

.testimonial-quote {
  position: relative;
  padding-left: 3rem;
}

.testimonial-quote .quote-container {
  padding-left: 160px;
}

.testimonial-quote .quote-container {
  padding-left: 0;
  padding-right: 160px;
}

.testimonial-quote cite {
  text-align: right;
}

.link {
  color: hsl(204, 86%, 53%);
}

.full-height {
  height: 100vh;
}

.hidden {
  display: none;
}

.is-hoverable:hover {
  cursor: pointer;
}

.teal-background {
  background-color: var(--teal);
}

.yellow-background {
  background-color: var(--yellow);
}

.blue-background {
  background-color: var(--blue);
}

.orange-background {
  background-color: var(--orange);
}

.orange-text {
  color: var(--orange) !important;
}

.blue-text {
  color: var(--blue) !important;
}

.modal-content {
  background-color: white;
  padding: 4rem;
  font-size: 1.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.modal.is-active {
  padding: 1rem;
}

.flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.media-content {
  overflow: visible !important;
}
