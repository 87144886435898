.display-flex {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}
.flex-1 {
  flex: 0 0 30%;
}
.flex-2 {
  flex: 0 0 60%;
}
.jump-to-container {
  margin-top: 1rem;
}
.tag {
  margin: 0 0.2rem 0 0.2rem;
}
.personality-box {
  height: 25rem;
  border: dotted 1px var(--teal);
  flex: 0 0 50%;
}
.personality-MB {
  height: 35rem;
  border: dotted 1px var(--teal);
  flex: 0 0 50%;
}
.personality-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}
.personality-connector-link {
  padding: 0.4rem 2rem;
}
.personality-connector-flex {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.heroTitle-mobile {
  padding-bottom: 1rem;
}

.heroSubtitle-mobile {
  padding: 1rem;
}

@media screen and (max-width: 980px) {
  .personality-box {
    height: 30rem;
  }
  .personality-MB {
    height: 42rem;
  }
}

@media screen and (max-width: 650px) {
  .personality-box {
    flex: auto;
    height: auto;
    border-bottom: dotted 1px var(--teal);
  }
  .personality-MB {
    flex: auto;
    height: auto;
    border-bottom: dotted 1px var(--teal);
  }
  .title .is-1 {
    font-size: 1rem;
  }
}
