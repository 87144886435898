.temp-message {
  margin-top: 4rem;
}

.homepage {
  text-align: center;
}

.homepage-welcome {
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
}

.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  margin: -5rem 6rem 0 6rem;
}

.welcome-title {
  font-size: 2.8rem;
  color: white;
  font-weight: 800;
}

.welcome-subtitle {
  font-size: 1.5rem;
  padding: 3rem 1rem 1.5rem 1rem;
  margin: 0 2rem 0 2rem;
  color: white;
  font-weight: 300;
}

.home-personality-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.homepage-attributes {
  display: flex;
  flex-flow: row nowrap;
  padding: 4rem;
  height: 100vh;
}

.bg-att {
  margin: 0 0.1rem 0 0.1rem;
}

.right {
  width: 50%;
  margin-left: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.left {
  width: 50%;
  margin-right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  text-align: center;
}

.media-content {
  display: flex;
  justify-content: center;
}

.randomLink:hover {
  cursor: pointer;
}

.splash-btn {
  border: none;
  background-color: white;
}

.homepage-personality blockquote::before {
  display: none;
}

#home-illustration {
  width: 70%;
}

.home-illustration-column {
  padding-bottom: 0;
  margin-bottom: -2rem;
}

#home-hero {
  padding: 6rem 1.5rem 0rem 1.5rem;
}

.about-image {
  width: 20rem;
}

.about-hero .container {
  padding: 1rem;
}

.attribute-discovery-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.attribute-discover {
  border: 1px solid var(--yellow);
  border-radius: 10px;
  padding: 1rem;
  margin: 2rem;
}

@media only screen and (min-width: 769px) and (max-width: 811px) {
  .home-illustration-column {
    margin-bottom: -12rem;
  }
}

@media only screen and (min-width: 811px) and (max-width: 950px) {
  .home-illustration-column {
    margin-bottom: -4rem;
  }
}

@media only screen and (max-width: 600px) {
  #home-hero {
    padding: 4rem 1.5rem 0rem 1.5rem;
  }
}
