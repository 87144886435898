.hero {
  background-color: var(--teal);
  padding-top: 3rem;
}

.heroContainer {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.heroTitle {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5rem;
}

#heroTitle {
  margin-bottom: 0;
}

#heroSubtitle {
  margin-top: 0;
}

.heroContainer-mobile {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.heroTitle-mobile {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.hero-body-mobile {
  padding-bottom: 2rem;
}

@media only screen and (max-width: 600px) {
  .heroContainer {
    justify-content: center;
  }
  .heroTitle {
    padding-left: 0;
  }
  .title {
    word-break: normal;
  }
}
