#password {
  -webkit-text-security: disc;
}

.input {
  width: 90%;
}

.register-title {
  text-align: center;
  padding-bottom: 1rem;
}

.disclaimer {
  display: flex;
  justify-content: center;
  font-size: 0.8rem;
  flex-flow: row wrap;
}

.is-grouped {
  margin-top: 1rem;
}

.error-message {
  color: red;
  font-weight: 600;
  display: block;
  padding-bottom: 1rem;
}
